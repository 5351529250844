import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem 0 15.0375rem 0',
  },

  header: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    background: '#FF7A01',
    color: '#fff',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
    textTransform: 'uppercase',
    fontWeight: 500,
    zIndex: 99,
  },

  noteContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },

  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
  },

  icon: {
    fontSize: '1rem',
    marginRight: '5px',
  },

  text: {
    flex: '1',
    fontSize: '1rem',
    fontWeight: '500',
  },

  note: {
    width: '100%',
    height: '6rem',
    borderRadius: '.5rem',
    border: '1px solid rgba(149, 149, 149, 0.4)',
    padding: '.5rem',
    fontSize: '1rem',
    fontWeight: '400',
    resize: 'none',
  },

  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    zIndex: 99,
  },

  amount: {
    minHeight: '4rem',
    padding: '.5rem 1rem',
    margin: '1rem 1rem 0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #eee',
    borderRadius: '10px',

    '& span': {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
  },

  plus: {
    minHeight: '4rem',
    margin: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      minWidth: '5.5rem',
      padding: '1rem',
      border: '2px solid #eee',
      borderRadius: '10px',
      fontSize: '1.25rem',
      fontWeight: 400,
      textAlign: 'center',
    },
  },

  nav: {
    width: '100%',
    minHeight: '4rem',
    margin: 0,
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
    background: '#FF7A01',
    color: '#fff',
  },
});

export default useStyles;
