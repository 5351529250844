/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';

import Item from './Item';
import { formatPrice } from '../../utils/format';
import useStyles from './styles';

function Complement({ complement }) {
  const classes = useStyles();

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      complement.items.map(item => {
        return { ...item, formattedPrice: formatPrice(item.price) };
      })
    );
  }, [complement]);

  return (
    <div id={`complement${complement.id}`} className={classes.root}>
      <div className={classes.header}>
        <span className={classes.headerName}>
          {complement.name.toLowerCase()}
        </span>
        <div className={classes.subheader}>
          <span className={classes.span}>
            {`${complement.selected} de ${complement.max}`}
          </span>

          {complement.required === 'S' &&
            complement.selected < complement.max && (
              <span className={classes.span}>obrigatório</span>
            )}

          {complement.required === 'S' &&
            complement.selected === complement.max && (
              <CheckIcon style={{ color: '#008000' }} />
            )}

          {complement.required === 'N' && (
            <span className={classes.span}>opcional</span>
          )}
        </div>
      </div>

      <div className={classes.list}>
        {items.map(item => (
          <Item key={item.id} required={complement.required} item={item} />
        ))}
      </div>
    </div>
  );
}

export default Complement;
