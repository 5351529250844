import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '16px',
    },
  },
  amount: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #eee',
    borderRadius: '10px',
    marginBottom: '16px',

    '& span': {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
  },
  switch: {
    margin: '10px 0 16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    height: '60px',
    background: '#4caf50',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#fff',
  },
}));

export default useStyles;
