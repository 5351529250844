import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  main: {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    position: 'fixed',
    width: '100vw',
    minHeight: '64px',
    left: 0,
    top: 0,
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
    textTransform: 'uppercase',
    fontWeight: 500,
    background: '#FF7A01',
    color: '#fff',
    zIndex: 99,
  },

  footer: {
    position: 'fixed',
    width: '100vw',
    minHeight: '64px',
    left: 0,
    bottom: 0,
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
    background: '#FF7A01',
    color: '#fff',
    zIndex: 99,

    '& a > svg': {
      color: '#fff',
    },
  },
}));

export default useStyles;
