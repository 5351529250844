import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '20px 20px 60px',
    background: '#fff',
    transform: 'translateY(-15px)',
    zIndex: 1000,
    borderRadius: '20px 20px 0 0',
    '& svg': {
      color: '#FF7A01',
    },
  },
  infoComand: {
    margin: 0,
    padding: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',

    fontWeight: 600,
  },
  ul: {
    padding: '0px',
    margin: '0px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0 0 20px',
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betweeen',
    flex: 1,
  },
  info: {
    marginLeft: '15px',
    fontWeight: 500,

    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 600,
    },
    '& span': {
      textTransform: 'capitalize',
    },
    flex: 1,
  },
  price: {
    '& span': {
      textAlign: 'right',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
  },
}));

export default useStyles;
