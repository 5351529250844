import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import useStyles from './styles';
import {
  complementAdd,
  complementRemove,
} from '../../../store/modules/item/actions';

function Item({ required, item }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { product: detalhe } = useSelector(state => state.item);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setProduct({ ...item });
  }, [item]);

  const handleRemove = useCallback(
    pro => {
      dispatch(complementRemove(pro));
    },
    [dispatch]
  );

  const handleAdd = useCallback(
    pro => {
      dispatch(complementAdd(pro));

      const complement = detalhe.complements.find(
        f => f.id === product.complement_id
      );

      if (complement && complement.selected + 1 === complement.max) {
        const index = detalhe.complements.findIndex(
          f => f.id === complement.id
        );

        if (index + 1 < detalhe.complements.length) {
          const { id } = detalhe.complements[index + 1];
          const anchor = document.querySelector(`#complement${id}`);
          if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }
    },
    [dispatch, detalhe, product]
  );

  return (
    product && (
      <div className={classes.root}>
        <div className={classes.fields}>
          <span className={classes.fieldsName}>{item.name.toLowerCase()}</span>
          <span>{item.description}</span>
          {required === 'N' && (
            <span className={classes.fieldsPrice}>
              {`+ ${item.formattedPrice}`}
            </span>
          )}
        </div>

        <div className={classes.actions}>
          {product.amount >= 1 && (
            <Fab
              size="small"
              aria-label="product-remove"
              onClick={() => handleRemove(product)}
            >
              <RemoveIcon />
            </Fab>
          )}

          {product.amount >= 1 && (
            <span className={classes.actionsAmount}>{product.amount}</span>
          )}

          <Fab
            size="small"
            aria-label="product-add"
            onClick={() => handleAdd(product)}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    )
  );
}

export default Item;
