import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';

import { Fastfood } from '@material-ui/icons';
import { formatPrice } from '../../../utils/format';

import useStyles from './styles';
import { removeItem } from '../../../store/modules/order/actions';
import { selected } from '../../../store/modules/item/actions';
import history from '../../../services/history';
import deletar from '../../../assets/delete.svg';
import edit from '../../../assets/edit.svg';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);

  const selecteds = useCallback(complements => {
    const data = [];
    complements.forEach(complement => {
      complement.items.forEach(complementItem => {
        if (complementItem.amount >= 1) {
          data.push(complementItem);
        }
      });
    });

    return data;
  }, []);

  const items = useSelector(state => {
    const { order } = state.order;
    if (order && order.items) {
      return order.items.map(orderItem => {
        return {
          ...orderItem,
          formattedSubtotal: formatPrice(orderItem.subtotal),
          selectedComplements: selecteds(orderItem.complements),
        };
      });
    }

    return [];
  });

  useEffect(() => {
    if (items.length === 0) history.push('/menu');
  }, [items]);

  const hanldeEdit = useCallback(
    product => {
      const data = { ...product, order: true };
      dispatch(selected(data));
      setItem(null);
    },
    [dispatch]
  );

  const hanldeRemove = useCallback(
    product => {
      const { key } = product;
      dispatch(removeItem(key));
      setItem(null);
    },
    [dispatch]
  );

  return items ? (
    <>
      <div className={classes.root}>
        {items.map(product => (
          <div
            key={JSON.stringify(product.key + product.id)}
            className={classes.item}
          >
            <div className={classes.body} onClick={() => hanldeEdit(product)}>
              <div className={classes.defaults}>
                <div className={classes.media}>
                  <Fastfood fontSize="large" />
                </div>
                <div className={classes.data}>
                  <span className={classes.span}>{`${product.amount}x`}</span>
                  <div className={classes.fields}>
                    <span className={classes.span}>{product.name}</span>
                  </div>
                </div>
              </div>

              {product.selectedComplements.length >= 1 && (
                <ul className={classes.complements}>
                  {product.selectedComplements &&
                    product.selectedComplements.map(complement => (
                      <li key={JSON.stringify(product.key + complement.id)}>
                        {`${complement.amount}x ${complement.name}`}
                      </li>
                    ))}
                </ul>
              )}

              {product.comment !== '' && (
                <div className={classes.comment}>
                  <SpeakerNotesIcon className={classes.icon} />
                  <span>{product.comment}</span>
                </div>
              )}
            </div>
            <div className={classes.menu}>
              <IconButton onClick={() => setItem(product)}>
                <MoreIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>

      <Drawer anchor="bottom" open={!!item} onClose={() => setItem(null)}>
        <div className={classes.listSettings}>
          {item && (
            <>
              <h1>{item.name}</h1>
              <ul className={classes.ul}>
                <div
                  className={classes.btnOptions}
                  onClick={() => hanldeEdit(item)}
                >
                  <span>editar</span>
                  <img src={edit} alt="editar" />
                </div>
                <div
                  className={classes.btnOptions}
                  onClick={() => hanldeRemove(item)}
                >
                  <span>remover</span>
                  <img src={deletar} alt="remover" />
                </div>
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  ) : null;
}

export default Items;
