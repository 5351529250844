import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    background: '#FF7A01',
    color: '#fff',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
    textTransform: 'uppercase',
    fontWeight: 500,
    zIndex: 99,
  },

  main: {
    margin: '4rem 0',
    display: 'flex',
    flexDirection: 'column',
  },

  section: {},

  section__name: {
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },

  section__button: {
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '.5rem',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
    background: '#fff',
    fontSize: '.75rem',

    '& svg': {
      color: '#0055bb',
    },
  },

  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FF7A01',
    color: '#fff',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
    zIndex: 99,
  },

  cart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& span': {
      position: 'absolute',
      top: '-.5rem',
      right: '1.3rem',
      height: '1.5rem',
      width: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '.75rem',
      background: '#119900',
      color: '#fff',

      fontSize: '0.75rem',
      fontWeight: 500,
    },
  },

  menu: {
    width: '16rem',
  },

  menu__button: {
    width: '100%',
    minHeight: '4rem',
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '.5rem',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
    background: '#fff',
    fontSize: '.75rem',
  },
});

export default useStyles;
