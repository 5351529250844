const urlBase = () => {
  const data = localStorage.getItem('server');
  if (data) {
    const server = JSON.parse(data);

    return `http://${server.ip}:${server.port}/datasnap/rest/tsm`;
  }

  return false;
};

export const login = async atendente => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/login`, {
      method: 'POST',
      body: JSON.stringify(atendente),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const enviarPedido = async pedido => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/pedido`, {
      method: 'POST',
      body: JSON.stringify(pedido),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const comandas = async () => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/comandas`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const items = async comanda => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/items/${comanda}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const resumo = async comanda => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/resumo/${comanda}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const pedidos = async comanda => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/pedidos/${comanda}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const auditoria = async comanda => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/auditoria/${comanda}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const categorias = async () => {
  const result = localStorage.getItem('categories');
  if (result) return JSON.parse(result);

  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/categories`, {
      method: 'GET',
    });

    const data = await response.json();

    if (data.result) {
      const categories = JSON.stringify(data.result);
      localStorage.setItem('categories', categories);
    }

    return data.result;
  }

  return null;
};

export const produtos = async () => {
  const result = localStorage.getItem('produtos');
  if (result) return JSON.parse(result);

  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/produtos`, {
      method: 'GET',
    });

    const data = await response.json();

    if (data.result) {
      const products = JSON.stringify(data.result);
      localStorage.setItem('produtos', products);
    }

    return data.result;
  }

  return null;
};

export const bestprodutos = async () => {
  const result = localStorage.getItem('bestprodutos');
  if (result) return JSON.parse(result);

  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/bestsellers`, {
      method: 'GET',
    });

    const data = await response.json();

    if (data.result) {
      const products = JSON.stringify(data.result);
      localStorage.setItem('bestprodutos', products);
    }

    return data.result;
  }

  return null;
};

export const comentarios = async produto => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/comments/${produto}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const setComment = async comment => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/comments`, {
      method: 'POST',
      body: JSON.stringify(comment),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const customers = async customer => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/customers`, {
      method: 'POST',
      body: JSON.stringify(customer),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const setCustomer = async customer => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/comandas`, {
      method: 'POST',
      body: JSON.stringify(customer),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const fechar = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/fechar`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const reabrir = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/reabrir`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const cancelar = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/cancelar`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const cancelarItem = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/cancelaritem`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const transferir = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/transferir`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};

export const transferirItem = async parameters => {
  const url = urlBase();

  if (url) {
    const response = await fetch(`${url}/transferiritem`, {
      method: 'POST',
      body: JSON.stringify(parameters),
    });

    const data = await response.json();

    return data.result;
  }

  return null;
};
