/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowBackIos, Assignment, Send as SendIcon } from '@material-ui/icons';
import Send from './Send';

import Items from './Items';

import useStyles from './styles';
import Loading from '../../components/Loading';

export default function Order() {
  const classes = useStyles();
  const [send, setSend] = useState(false);
  const { loading } = useSelector(state => state.order);
  const { user } = useSelector(state => state.auth);

  return (
    <>
      <main className={classes.main}>
        <header className={classes.header}>
          <Assignment fontSize="large" />
          <span>pedido</span>
        </header>

        <Items />

        <footer className={classes.footer}>
          <Link to="/menu">
            <ArrowBackIos fontSize="large" />
          </Link>

          <SendIcon fontSize="large" onClick={() => setSend(true)} />
        </footer>
      </main>

      <Send send={send} setSend={setSend} user={user} />

      <Loading loading={loading} />
    </>
  );
}
