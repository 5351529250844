import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { comandaOrdersRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Orders() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, comanda } = useSelector(state => state.data);

  useEffect(() => {
    if (!loading && comanda && !comanda.orders)
      dispatch(comandaOrdersRequest(comanda));
  }, [loading, comanda, dispatch]);

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {comanda &&
          comanda.orders &&
          comanda.orders.map(pedido => (
            <li key={pedido.pedido_id} className={classes.item}>
              <div>
                <span>{pedido.momento}</span>
                <span>{pedido.autor}</span>
              </div>

              <ul key={pedido.pedido_id}>
                {pedido.items.map((item, index) => (
                  <li key={JSON.stringify({ ...item, index })}>
                    <span className={classes.amount}>{item.quantidade}</span>
                    <span className={classes.name}>{item.nome}</span>
                  </li>
                ))}
              </ul>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Orders;
