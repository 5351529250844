import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Remove, Add } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';

import { Button } from '@material-ui/core';
import { closeComandaRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function People({ closure, setClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (closure) setAmount(1);
  }, [closure]);

  const handleConfirm = () => {
    const { comanda_id: prevenda_id } = closure;
    const { vendedor_id } = user;
    const pessoas = amount;
    const imprimir = 'S';
    setClosure(null);

    dispatch(
      closeComandaRequest({
        prevenda_id,
        operador_id: 0,
        vendedor_id,
        pessoas,
        imprimir,
      })
    );
  };

  return (
    <Drawer anchor="bottom" open={!!closure} onClose={() => setClosure(null)}>
      <div className={classes.root}>
        <h1>pessoas</h1>
        <div className={classes.amount}>
          <Remove
            onClick={() => {
              if (amount >= 2) setAmount(prev => prev - 1);
            }}
            fontSize="large"
          />
          <span>{amount}</span>
          <Add onClick={() => setAmount(prev => prev + 1)} fontSize="large" />
        </div>
        <div className={classes.plus}>
          <span onClick={() => setAmount(prev => prev + 5)}>+ 5</span>
          <span onClick={() => setAmount(prev => prev + 10)}>+ 10</span>
          <span onClick={() => setAmount(prev => prev + 15)}>+ 15</span>
        </div>

        <Button
          variant="contained"
          className={classes.button}
          onClick={handleConfirm}
        >
          confirmar
        </Button>
      </div>
    </Drawer>
  );
}

export default People;
