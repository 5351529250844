import React from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Subject, LockOpen, SyncAlt, HighlightOff } from '@material-ui/icons';
import useStyles from './styles';

function Options({
  comanda,
  setComanda,
  hanldeClosure,
  handleReopen,
  hanldeTransfer,
  hanldeCancel,
}) {
  const classes = useStyles();
  const { user } = useSelector(state => state.auth);

  return (
    <Drawer anchor="bottom" open={!!comanda} onClose={() => setComanda(null)}>
      <div className={classes.root}>
        {comanda && <h1>{`COMANDA ${comanda.numero}`}</h1>}

        <ul className={classes.ul}>
          {comanda && comanda.situacao === 'A' && (
            <>
              {user && user.fechar === 'S' && (
                <button
                  type="button"
                  className={classes.option}
                  onClick={() => hanldeClosure(comanda)}
                >
                  <span>fechar</span>
                  <Subject />
                </button>
              )}

              {user && user.transferir === 'S' && (
                <button
                  type="button"
                  className={classes.option}
                  onClick={() => hanldeTransfer(comanda)}
                >
                  <span>transferir</span>
                  <SyncAlt />
                </button>
              )}

              {user && user.cancelar === 'S' && (
                <button
                  type="button"
                  className={classes.option}
                  onClick={() => hanldeCancel(comanda)}
                >
                  <span>cancelar</span>
                  <HighlightOff />
                </button>
              )}
            </>
          )}

          {comanda && comanda.situacao === 'F' && (
            <>
              {user && user.reabrir === 'S' && (
                <button
                  type="button"
                  className={classes.option}
                  onClick={() => handleReopen(comanda)}
                >
                  <span>reabrir</span>
                  <LockOpen />
                </button>
              )}
            </>
          )}
        </ul>
      </div>
    </Drawer>
  );
}

export default Options;
