import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import { enviarPedido } from '../../../services/api';

import { orderFailure, orderSuccess } from './actions';

export function included({ payload: { item } }) {
  toast.success('O item foi adicionado com sucesso');
  if (item.order) history.push('/order');
  else history.push('/menu');
}

export function removed() {
  toast.success('O item foi removido com sucesso');
}

export function* send({ payload }) {
  try {
    const { order } = payload;
    const response = yield call(enviarPedido, order);
    const data = response;
    if (data && data.success) {
      yield put(orderSuccess());
      toast.success('O seu pedido foi enviado com sucesso');
    } else if (data && !data.success) {
      yield put(orderFailure());
      toast.error(data.message);
    } else {
      yield put(orderFailure());
      toast.error('Houve um erro inesperado. Tente novamente');
    }
  } catch (err) {
    yield put(orderFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export default all([
  takeLatest('@order/ADD_ITEM', included),
  takeLatest('@order/REMOVE_ITEM', removed),
  takeLatest('@order/ORDER_REQUEST', send),
]);
