import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import md5 from 'md5';
import { transferRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';

function Transfer({ transferComanda, setTransferComanda }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [origin, setOrigin] = useState();
  const [destiny, setDestiny] = useState('');
  const [comment, setComment] = useState('');
  const [client, setClient] = useState(false);

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (transferComanda) {
      const { numero } = transferComanda;
      setOrigin(numero);
      setDestiny('');
      setComment('');
      setClient(false);
    }
  }, [transferComanda]);

  const hanldeKeyPress = useCallback(
    val => {
      const value = `${destiny}`;
      if (value.trim().length === 0) {
        if (val === '0') return false;
        setDestiny(val);
      } else {
        setDestiny(prev => `${prev}${val}`);
      }
      return false;
    },
    [destiny]
  );

  const hanldeCancel = () => {
    const value = `${destiny}`;
    if (value.trim().length === 0) {
      setTransferComanda(null);
    } else {
      setDestiny('');
    }
  };

  const handleConfirm = useCallback(
    ({
      origin: origem,
      destiny: destino,
      comment: motivo,
      client: cliente,
    }) => {
      const { vendedor_id } = user;
      const key = md5(new Date().getTime()).toUpperCase();

      dispatch(
        transferRequest({
          origem,
          destino,
          operador_id: 0,
          vendedor_id,
          motivo,
          cliente: cliente ? 'S' : 'N',
          key,
        })
      );
    },
    [user, dispatch]
  );

  return (
    <Drawer
      anchor="bottom"
      open={!!transferComanda}
      onClose={() => setTransferComanda(null)}
    >
      <div className={classes.root}>
        <div className={classes.destiny}>
          <span>comanda de destino</span>
          <span>{destiny}</span>
        </div>
        <div className={classes.keyboard}>
          <div className={classes.row}>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('1')}
            >
              1
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('2')}
            >
              2
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('3')}
            >
              3
            </span>
          </div>
          <div className={classes.row}>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('4')}
            >
              4
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('5')}
            >
              5
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('6')}
            >
              6
            </span>
          </div>
          <div className={classes.row}>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('7')}
            >
              7
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('8')}
            >
              8
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('9')}
            >
              9
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.cancel} onClick={hanldeCancel}>
              cancel
            </span>
            <span
              className={classes.number}
              onClick={() => hanldeKeyPress('0')}
            >
              0
            </span>
          </div>
        </div>

        <TextField
          error={comment.length < 5}
          label="Motivo"
          multiline
          rows={3}
          variant="outlined"
          value={comment}
          onChange={event => {
            if (event.target.value.length <= 30) setComment(event.target.value);
          }}
        />

        <div className={classes.switch}>
          <FormControlLabel
            control={
              <Switch checked={client} onChange={() => setClient(!client)} />
            }
            label="Cliente"
          />
        </div>

        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            handleConfirm({ origin, destiny, comment, client });
          }}
          disabled={destiny.length <= 0 || comment.length < 5}
        >
          confirmar
        </Button>
      </div>
    </Drawer>
  );
}

export default Transfer;
