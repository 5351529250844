import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { orderRequest } from '../../../store/modules/order/actions';
import useStyles from './styles';

function Send({ send, setSend, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [table, setTable] = useState(0);
  const [text, setText] = useState('');

  const { categories = [] } = useSelector(state => state.data);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    if (send) {
      setTable(0);
      setText('');
    }
  }, [send]);

  const handleKeyPress = val => {
    const value = `${text}`;
    if (value.trim().length === 0) {
      if (val === '0') return false;
      setText(val);
    } else {
      setText(prev => [`${prev}${val}`]);
    }
    return false;
  };

  const hanldeCancel = () => {
    const value = `${text}`;

    if (value.trim().length === 0 && table >= 1) {
      setText(String(table));
      setTable(0);
      return;
    }

    if (value.trim().length === 0) {
      setSend(false);
      return;
    }

    setText('');
  };

  const hanldeEnter = async () => {
    const value = Number(text);

    if (value === 0) return;

    if (user.comanda === 'S' && table === 0) {
      setTable(value);
      setText('');
      return;
    }

    if (table === 0) setTable(value);

    await setSend(false);

    const { vendedor_id, bigger } = user;

    const pedido = {
      user_id: vendedor_id,
      table: user.comanda === 'S' ? table : 0,
      control: value,
      items: order.items.map(item => {
        const subitems = [];
        const category = categories.find(f => f.id === item.category_id);
        const { combinable } = category;

        item.complements.forEach(complement => {
          complement.items
            .filter(subitem => subitem.amount >= 1)
            .forEach(subitem => {
              const { product_id, amount, price } = subitem;

              if (combinable === 'S') {
                if (complement.required === 'S') {
                  if (bigger === 'S') {
                    const biggerPrice = complement.items
                      .filter(sub => sub.amount >= 1)
                      .reduce((currentPrice, obj) => {
                        return currentPrice >= obj.price
                          ? currentPrice
                          : obj.price;
                      }, 0);

                    const amountSelected = complement.items
                      .filter(sub => sub.amount >= 1)
                      .reduce((currentAmount, obj) => {
                        return currentAmount + obj.amount;
                      }, 0);

                    const unitPrice = biggerPrice / amountSelected;

                    subitems.push({
                      product_id,
                      amount,
                      price: unitPrice,
                      subtotal: unitPrice * amount,
                    });
                  } else {
                    const amountSelected = complement.items
                      .filter(sub => sub.amount >= 1)
                      .reduce((currentAmount, obj) => {
                        return currentAmount + obj.amount;
                      }, 0);

                    const unitPrice = price / amountSelected;

                    subitems.push({
                      product_id,
                      amount,
                      price: unitPrice,
                      subtotal: unitPrice * amount,
                    });
                  }
                } else {
                  subitems.push({
                    product_id,
                    amount,
                    price,
                    subtotal: price * amount,
                  });
                }
              } else {
                subitems.push({
                  product_id,
                  amount,
                  price: complement.required === 'S' ? 0 : price,
                  subtotal: complement.required === 'S' ? 0 : price * amount,
                });
              }
            });
        });

        const { id: product_id, amount, price, comment } = item;
        const totalsubitems = subitems.reduce((a, b) => {
          return a + b.subtotal;
        }, 0);

        return {
          product_id,
          amount,
          price:
            combinable === 'S' && totalsubitems >= 1
              ? totalsubitems
              : price + totalsubitems,
          comment,
          subitems,
        };
      }),
    };

    dispatch(orderRequest(pedido));
  };

  return (
    <Drawer anchor="bottom" open={send} onClose={() => setSend(false)}>
      <div className={classes.root}>
        <div className={classes.comanda}>
          {user.comanda === 'S' && table === 0 ? (
            <span>mesa</span>
          ) : (
            <span>comanda</span>
          )}

          <span className={classes.number}>
            {`${text}`.trim().length >= 1 ? `${text}` : '?'}
          </span>
        </div>
        <div className={classes.keyboard}>
          <div className={classes.row}>
            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('1')}
            >
              <span>1</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('2')}
            >
              <span>2</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('3')}
            >
              <span>3</span>
            </button>
          </div>
          <div className={classes.row}>
            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('4')}
            >
              <span>4</span>
            </button>
            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('5')}
            >
              <span>5</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('6')}
            >
              <span>6</span>
            </button>
          </div>
          <div className={classes.row}>
            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('7')}
            >
              <span>7</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('8')}
            >
              <span>8</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('9')}
            >
              <span>9</span>
            </button>
          </div>
          <div className={classes.row}>
            <button
              type="button"
              className={classes.cancel}
              onClick={hanldeCancel}
            >
              <span>cancel</span>
            </button>

            <button
              type="button"
              className={classes.number}
              onClick={() => handleKeyPress('0')}
            >
              <span>0</span>
            </button>

            <button
              type="button"
              className={classes.enter}
              onClick={hanldeEnter}
            >
              <span>enter</span>
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Send;
