import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { decode } from 'js-base64';
import { formatPrice } from '../../../utils/format';

import history from '../../../services/history';
import {
  comandas,
  categorias,
  produtos,
  resumo,
  items,
  pedidos,
  auditoria,
  fechar,
  reabrir,
  cancelar,
  cancelarItem,
  transferir,
  transferirItem,
} from '../../../services/api';

import {
  decodedServer,
  comandasFailure,
  comandasSuccess,
  comandaFailure,
  comandaSuccess,
  categoriesFailure,
  categoriesSuccess,
  itemsFailure,
  itemsSuccess,
  closeComandaFailure,
  closeComandaSuccess,
  reopenComandaFailure,
  reopenComandaSuccess,
  cancelFailure,
  cancelSuccess,
  cancelItemFailure,
  cancelItemSuccess,
  transferFailure,
  transferSuccess,
  transferItemFailure,
  transferItemSuccess,
} from './actions';

export function* decodeServer({ payload }) {
  const { server } = payload;
  if (server) {
    const decoded = decode(server);
    const [ip, port] = decoded.split('|');
    yield put(decodedServer({ ip, port }));
  }
}

export function* commands() {
  try {
    const response = yield call(comandas);
    const data = response || [];
    yield put(
      comandasSuccess([
        ...data.map(item => {
          const total = item.subtotal + item.taxa_servico;
          return {
            ...item,
            total,
            formattedSubtotal: formatPrice(item.subtotal),
            formattedTaxa: formatPrice(item.taxa_servico),
            formattedTotal: formatPrice(total),
            timeFomatted: format(
              new Date(item.ultimo_pedido.split(' ').join('T')),
              "HH'h'mm",
              {
                locale: ptBR,
              }
            ),
          };
        }),
      ])
    );
  } catch (err) {
    yield put(comandasFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* command({ payload }) {
  try {
    const { comanda } = payload;
    const response = yield call(resumo, comanda.comanda_id);
    const data = response || [];
    yield put(
      comandaSuccess({
        ...comanda,
        resumo: [
          ...data.map(item => {
            return {
              ...item,
              formattedSubtotal: formatPrice(item.subtotal),
            };
          }),
        ],
        formattedSubtotal: formatPrice(
          data.reduce((accumulator, item) => {
            return accumulator + item.subtotal;
          }, 0)
        ),
        formattedTaxa: formatPrice(
          data.reduce((accumulator, item) => {
            return accumulator + item.servico;
          }, 0)
        ),
        formattedTotal: formatPrice(
          data.reduce((accumulator, item) => {
            return accumulator + item.subtotal + item.servico;
          }, 0)
        ),
        items: null,
        audits: null,
        orders: null,
      })
    );
    history.push('/detail');
  } catch (err) {
    yield put(comandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* commandItems({ payload }) {
  try {
    const { comanda } = payload;
    const response = yield call(items, comanda.comanda_id);
    const data = response || [];
    yield put(
      comandaSuccess({
        ...comanda,
        items: [...data],
      })
    );
  } catch (err) {
    yield put(comandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* comandaOrders({ payload }) {
  try {
    const { comanda } = payload;
    const response = yield call(pedidos, comanda.comanda_id);
    const data = response || [];
    yield put(
      comandaSuccess({
        ...comanda,
        orders: [
          ...data.map(item => {
            return {
              ...item,
              momento: format(
                new Date(item.momento.split(' ').join('T')),
                'dd/MM/yyyy HH:mm:ss',
                {
                  locale: ptBR,
                }
              ),
            };
          }),
        ],
      })
    );
  } catch (err) {
    yield put(comandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* comandaAudits({ payload }) {
  try {
    const { comanda } = payload;
    const response = yield call(auditoria, comanda.comanda_id);
    const data = response || [];
    yield put(
      comandaSuccess({
        ...comanda,
        audits: [
          ...data.map(item => {
            return {
              ...item,
              momento: format(
                new Date(item.momento.split(' ').join('T')),
                'dd/MM/yyyy HH:mm:ss',
                {
                  locale: ptBR,
                }
              ),
            };
          }),
        ],
      })
    );
  } catch (err) {
    yield put(comandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* categories() {
  try {
    const response = yield call(categorias);
    const data = response || [];
    yield put(
      categoriesSuccess(
        data.map(category => {
          return {
            ...category,
            products: category.products.map(product => {
              return {
                ...product,
                formattedPrice: formatPrice(product.price),
              };
            }),
          };
        })
      )
    );
    history.push('/menu');
  } catch (err) {
    yield put(categoriesFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* products({ payload }) {
  try {
    const { category } = payload;
    const response = yield call(produtos);
    const data = response || [];
    const success = [
      ...data
        .filter(f => f.categoria_id === category.categoria_id)
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          return a.nome > b.nome ? 1 : 0;
        }),
    ];
    yield put(itemsSuccess(success));
    history.push('/items');
  } catch (err) {
    yield put(itemsFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* closeComanda({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(fechar, parameters);
    if (response && response.success) {
      yield put(closeComandaSuccess(parameters));
      toast.success('A comanda foi fechada com sucesso');
    } else if (response && !response.success) {
      yield put(closeComandaFailure());
      toast.error(response.message);
    } else {
      yield put(closeComandaFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(closeComandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* reopenComanda({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(reabrir, parameters);
    if (response && response.success) {
      yield put(reopenComandaSuccess(parameters));
      toast.success('A comanda foi reaberta com sucesso');
    } else if (response && !response.success) {
      yield put(reopenComandaFailure());
      toast.error(response.message);
    } else {
      yield put(reopenComandaFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(reopenComandaFailure());
    toast.error(
      'Houve uma falha ao tentar consultar os dados. Tente novamente'
    );
  }
}

export function* cancel({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(cancelar, parameters);
    if (response && response.success) {
      yield put(cancelSuccess(parameters));
      toast.success('Cancelado com sucess');
    } else if (response && !response.success) {
      yield put(cancelFailure());
      toast.error(response.message);
    } else {
      yield put(cancelFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(cancelFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export function* cancelItem({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(cancelarItem, parameters);
    if (response && response.success) {
      yield put(cancelItemSuccess(parameters));
      toast.success('Cancelado com sucesso');
    } else if (response && !response.success) {
      yield put(cancelItemFailure());
      toast.error(response.message);
    } else {
      yield put(cancelItemFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(cancelItemFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export function* transfer({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(transferir, parameters);
    if (response && response.success) {
      yield put(transferSuccess(parameters));
      toast.success('Transferido com sucesso');
    } else if (response && !response.success) {
      yield put(transferFailure());
      toast.error(response.message);
    } else {
      yield put(transferFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(transferFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export function* transferItem({ payload }) {
  try {
    const { parameters } = payload;
    const response = yield call(transferirItem, parameters);
    if (response && response.success) {
      yield put(transferItemSuccess(parameters));
      toast.success('Transferido com sucesso');
    } else if (response && !response.success) {
      yield put(transferItemFailure());
      toast.error(response.message);
    } else {
      yield put(transferItemFailure());
      toast.error('Houve um erro inesperado, Tente novamente');
    }
  } catch (err) {
    yield put(transferItemFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export default all([
  takeLatest('@data/DECODE_SERVER', decodeServer),
  takeLatest('@data/COMANDAS_REQUEST', commands),
  takeLatest('@data/COMANDA_REQUEST', command),
  takeLatest('@data/COMANDA_ITEMS_REQUEST', commandItems),
  takeLatest('@data/COMANDA_ORDERS_REQUEST', comandaOrders),
  takeLatest('@data/COMANDA_AUDITS_REQUEST', comandaAudits),
  takeLatest('@data/CATEGORIES_REQUEST', categories),
  takeLatest('@data/ITEMS_REQUEST', products),
  takeLatest('@data/CLOSE_COMANDA_REQUEST', closeComanda),
  takeLatest('@data/REOPEN_COMANDA_REQUEST', reopenComanda),
  takeLatest('@data/CANCEL_REQUEST', cancel),
  takeLatest('@data/CANCEL_ITEM_REQUEST', cancelItem),
  takeLatest('@data/TRANSFER_REQUEST', transfer),
  takeLatest('@data/TRANSFER_ITEM_REQUEST', transferItem),
]);
