import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Replay, MoreVert } from '@material-ui/icons';
import Number from '../../../components/Nunber';
import {
  comandasRequest,
  comandaRequest,
  reopenComandaRequest,
} from '../../../store/modules/data/actions';

import Options from '../Options';
import People from '../People';
import Transfer from '../Transfer';
import Cancellation from '../Cancellation';

import useStyles from './styles';

function Body() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [comanda, setComanda] = useState(null);
  const [closure, setClosure] = useState(null);
  const [transferComanda, setTransferComanda] = useState(null);
  const [cancelComanda, setCancelComanda] = useState(null);
  const { user } = useSelector(state => state.auth);
  const { reload, comandas: list = [] } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(comandasRequest());
  }, [dispatch]);

  useEffect(() => {
    if (reload) {
      setTransferComanda(null);
      setCancelComanda(null);
      dispatch(comandasRequest());
    }
  }, [reload, dispatch]);

  const hanldeComanda = data => {
    dispatch(comandaRequest(data));
  };

  const hanldeClosure = data => {
    setComanda(null);
    setClosure(data);
  };

  const handleReopen = data => {
    const { comanda_id: prevenda_id } = data;
    const { vendedor_id } = user;
    setComanda(null);

    dispatch(
      reopenComandaRequest({
        prevenda_id,
        operador_id: 0,
        vendedor_id,
      })
    );
  };

  const hanldeTransfer = data => {
    setComanda(null);
    setTransferComanda(data);
  };

  const hanldeCancel = data => {
    setComanda(null);
    setCancelComanda(data);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.infoComand}>
          <span>{`${list.length} comandas`}</span>
          <Replay
            fontSize="default"
            onClick={() => dispatch(comandasRequest())}
          />
        </div>
        <ul className={classes.ul}>
          {list.map(item => (
            <div key={item.comanda_id} className={classes.item}>
              <div
                className={classes.fields}
                onClick={() => hanldeComanda(item)}
              >
                <Number comanda={item} />
                <div className={classes.info}>
                  <h1>{item.cliente}</h1>
                  <span>{`pedidos: ${item.pedidos}`}</span>
                  <span>{`último pedido: ${item.timeFomatted}`}</span>
                </div>
                <div className={classes.price}>
                  <span>{item.formattedTotal}</span>
                </div>
              </div>

              {item.situacao === 'A' &&
                (user.fechar === 'S' ||
                  user.transferir === 'S' ||
                  user.cancelar === 'S') && (
                  <MoreVert fontSize="large" onClick={() => setComanda(item)} />
                )}

              {item.situacao === 'F' && user.reabrir === 'S' && (
                <MoreVert fontSize="large" onClick={() => setComanda(item)} />
              )}
            </div>
          ))}
        </ul>
      </div>

      <Options
        comanda={comanda}
        setComanda={setComanda}
        hanldeClosure={hanldeClosure}
        handleReopen={handleReopen}
        hanldeTransfer={hanldeTransfer}
        hanldeCancel={hanldeCancel}
      />

      <People closure={closure} setClosure={setClosure} />

      <Transfer
        transferComanda={transferComanda}
        setTransferComanda={setTransferComanda}
      />

      <Cancellation
        cancelComanda={cancelComanda}
        setCancelComanda={setCancelComanda}
      />
    </>
  );
}

export default Body;
