import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';

import { Add, Remove } from '@material-ui/icons';
import md5 from 'md5';
import { cancelItemRequest } from '../../../../store/modules/data/actions';

import useStyles from './styles';

function Cancellation({ cancelItem, setCancelItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [itemId, setItemId] = useState();
  const [amount, setAmount] = useState();
  const [comment, setComment] = useState('');
  const [client, setClient] = useState(false);

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (cancelItem) {
      const { item_id, quantidade } = cancelItem;
      setItemId(item_id);
      setAmount(quantidade);
      setComment('');
      setClient(false);
    }
  }, [cancelItem]);

  const handleConfirm = useCallback(
    ({
      itemId: item_id,
      amount: quantidade,
      comment: motivo,
      client: cliente,
    }) => {
      const { vendedor_id } = user;
      const key = md5(new Date().getTime()).toUpperCase();

      dispatch(
        cancelItemRequest({
          item_id,
          operador_id: 0,
          vendedor_id,
          quantidade,
          motivo,
          cliente: cliente ? 'S' : 'N',
          key,
        })
      );
    },
    [user, dispatch]
  );

  return (
    <Drawer
      anchor="bottom"
      open={!!cancelItem}
      onClose={() => setCancelItem(null)}
    >
      <div className={classes.root}>
        <h1>cancelar item</h1>
        <div className={classes.amount}>
          <Remove
            onClick={() => {
              if (amount >= 2) setAmount(prev => prev - 1);
            }}
            fontSize="large"
          />
          <span>{amount}</span>
          <Add
            onClick={() => {
              if (amount < cancelItem.quantidade) setAmount(prev => prev + 1);
            }}
            fontSize="large"
          />
        </div>

        <TextField
          error={comment.length < 5}
          label="Motivo"
          multiline
          rows={3}
          variant="outlined"
          value={comment}
          onChange={event => {
            if (event.target.value.length <= 30) setComment(event.target.value);
          }}
        />

        <div className={classes.switch}>
          <FormControlLabel
            control={
              <Switch checked={client} onChange={() => setClient(!client)} />
            }
            label="Cliente"
          />
        </div>

        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            handleConfirm({ itemId, amount, comment, client });
          }}
          disabled={comment.length < 5}
        >
          confirmar
        </Button>
      </div>
    </Drawer>
  );
}

export default Cancellation;
