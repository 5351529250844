import React, { useCallback, useEffect, useState } from 'react';

import {
  ArrowBackIos,
  Subject,
  ShoppingCartOutlined,
  Fastfood,
} from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@material-ui/core';
import useStyles from './styles';
import { selected } from '../../store/modules/item/actions';
import history from '../../services/history';

function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [menu, setMenu] = useState('menu');
  const [menuModal, setMenuModal] = useState(false);
  const { categories: list = [] } = useSelector(state => state.data);
  const count = useSelector(state => {
    const { order } = state.order;
    return order ? order.items.length : 0;
  });

  const scrollActive = useCallback(() => {
    const sections = document.querySelectorAll('section[id]');
    const scrollY = window.pageYOffset;

    const data = [];
    sections.forEach(current => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 1;
      const sectionId = current.getAttribute('id');

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        data.push(parseInt(sectionId, 10));
      }
    });

    if (data.length >= 1) {
      const currentId = data[data.length - 1];
      if (currentId !== id) setId(currentId);
    } else setId(0);
  }, [id]);

  useEffect(() => {
    if (list && list.length >= 1) {
      window.addEventListener('scroll', scrollActive);
    }
    return () => window.removeEventListener('scroll', scrollActive);
  }, [list, scrollActive]);

  useEffect(() => {
    if (list && id >= 1) {
      const section = list.find(f => f.id === id);
      if (section) setMenu(section.name);
    } else setMenu('menu');
  }, [id, list]);

  const hanldeClose = useCallback(() => {
    history.push('/');
  }, []);

  const hanldeOrder = useCallback(() => {
    if (count >= 1) history.push('/order');
  }, [count]);

  const handleProduct = useCallback(
    product => {
      dispatch(selected(product));
    },
    [dispatch]
  );

  const handleCategory = useCallback(category => {
    const anchor = document.getElementById(category.id);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setMenuModal(false);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <header className={classes.header}>
          <Subject onClick={() => setMenuModal(true)} fontSize="large" />
          <span>{menu}</span>
        </header>

        <main className={classes.main}>
          {list.map(category => (
            <section
              key={category.id}
              id={category.id}
              className={classes.section}
            >
              <span className={classes.section__name}>{category.name}</span>
              <ul>
                {category.products.map(product => (
                  <li key={product.id}>
                    <button
                      type="button"
                      className={classes.section__button}
                      onClick={() => handleProduct(product)}
                    >
                      <Fastfood fontSize="large" />
                      <span>{product.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </main>

        <footer className={classes.footer}>
          <ArrowBackIos onClick={hanldeClose} fontSize="large" />
          <div className={classes.cart} onClick={hanldeOrder}>
            {count >= 1 && <span>{count}</span>}
            <ShoppingCartOutlined onClick={hanldeOrder} fontSize="large" />
          </div>
        </footer>
      </div>

      <Drawer
        anchor="right"
        open={menuModal}
        onClose={() => setMenuModal(false)}
      >
        <div className={classes.menu}>
          <ul>
            {list.map(category => (
              <li key={category.id}>
                <button
                  type="button"
                  className={classes.menu__button}
                  onClick={() => handleCategory(category)}
                >
                  <Subject fontSize="large" />
                  <span>{category.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
    </>
  );
}

export default Menu;
