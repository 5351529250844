import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Add,
  ArrowBackIos,
  Fastfood,
  Remove,
  Check,
  SpeakerNotes,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import useStyles from './styles';
import Complement from '../../components/Complement';
import { addItem } from '../../store/modules/order/actions';
import history from '../../services/history';

function Item() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const keyDefault = '';
  const complementsDefault = [];
  const amountDefault = 0;
  const commentDefault = '';
  const subtotalDefault = 0;

  const { product } = useSelector(state => state.item);
  const [key, setKey] = useState(keyDefault);
  const [complements, setComplements] = useState(complementsDefault);
  const [amount, setAmount] = useState(amountDefault);
  const [comment, setComment] = useState(commentDefault);
  const [subtotal, setSubtotal] = useState(subtotalDefault);

  useEffect(() => {
    if (product) {
      setComplements(product.complements);
      if (key === keyDefault || product.key !== key) {
        setKey(product.key);
        setAmount(product.amount);
        setComment(product.comment);
      }
    } else {
      setKey(keyDefault);
      setComplements(complementsDefault);
      setAmount(amountDefault);
      setComment(commentDefault);
    }
  }, [product, key, complementsDefault]);

  useEffect(() => {
    if (product && amount >= 1) {
      const optional = product.complements.filter(f => f.required === 'N');
      const optionalPrices = optional.reduce((a, complement) => {
        const complementPrices = complement.items.reduce((b, item) => {
          return b + item.amount * item.price;
        }, 0);

        return a + complementPrices;
      }, 0);
      const sub = amount * (optionalPrices + product.price);
      setSubtotal(sub);
    }
  }, [amount, product]);

  const hanldeClose = useCallback(() => {
    if (product.order) history.push('/order');
    else history.push('/menu');
  }, [product]);

  const handleChangeComment = useCallback(event => {
    setComment(event.target.value);
  }, []);

  const handleChangeAmount = useCallback(changedAmount => {
    if (changedAmount >= 1) setAmount(changedAmount);
  }, []);

  const handleConfirm = useCallback(() => {
    const required = product.complements.find(
      f => f.required === 'S' && f.selected < f.max
    );

    if (required) {
      toast.error(
        'É preciso escolher todos os itens obrigatorios antes de adicionar.'
      );

      return;
    }

    const data = {
      ...product,
      amount,
      comment,
      subtotal,
    };

    dispatch(addItem({ ...data }));
  }, [dispatch, product, amount, comment, subtotal]);

  return (
    product && (
      <main className={classes.main}>
        <header className={classes.header}>
          <Fastfood fontSize="large" />
          <span>{product.name}</span>
        </header>

        {complements.map(complement => (
          <Complement key={complement.id} complement={complement} />
        ))}

        <div className={classes.noteContainer}>
          <div className={classes.label}>
            <SpeakerNotes className={classes.icon} />
            <span className={classes.text}>Alguma observação?</span>
            <span>{`${comment.length}/100`}</span>
          </div>
          <textarea
            className={classes.note}
            value={comment}
            onChange={handleChangeComment}
            maxLength={100}
            placeholder="Ex: Tirar a cebola, maionese à parte, ponto da carne etc"
          />
        </div>

        <footer className={classes.footer}>
          <div className={classes.amount}>
            <Remove
              onClick={() => handleChangeAmount(amount - 1)}
              fontSize="large"
            />
            <span>{amount}</span>
            <Add
              onClick={() => handleChangeAmount(amount + 1)}
              fontSize="large"
            />
          </div>

          <div className={classes.plus}>
            <span onClick={() => handleChangeAmount(amount + 5)}>+ 5</span>
            <span onClick={() => handleChangeAmount(amount + 10)}>+ 10</span>
            <span onClick={() => handleChangeAmount(amount + 15)}>+ 15</span>
          </div>

          <nav className={classes.nav}>
            <ArrowBackIos onClick={hanldeClose} fontSize="large" />
            <Check onClick={handleConfirm} fontSize="large" />
          </nav>
        </footer>
      </main>
    )
  );
}

export default Item;
